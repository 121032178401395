import api from '../api';

import { types, flow } from 'mobx-state-tree';

const Banners = types.model({
  cheliang: types.array(types.frozen()),
  home: types.array(types.frozen()),
  ershou: types.array(types.frozen()),
  fang: types.array(types.frozen()),
  fuwu: types.array(types.frozen()),
  gongzuo: types.array(types.frozen()),
  huodong: types.array(types.frozen()),
  lvyou: types.array(types.frozen()),
  float: types.array(types.frozen()),
  aixue: types.array(types.frozen()),
  shengyi: types.array(types.frozen()),
  shequ: types.array(types.frozen())
});

const ConfigStore = types
  .model({
    banners: Banners,
    area: types.frozen(),
    city: types.frozen(),
    currentActivity: types.frozen(),
    category: ''
  })
  .actions(self => {
    const init = flow(function*() {
      const { data } = yield api.initConfig();
      const socialFeed = yield api.topic.socialFeed({
        cursor: null
      });
      if (data && !data.code) {
        const _city = data.city.map(c => {
          return { ...c, value: c.id, label: c.chinese_name + ' ' + c.name };
        });

        const _area = Object.keys(data.area).reduce((sum, key) => {
          const subarea = data.area[key];
          sum[key] = subarea.map(a => {
            return { ...a, value: a.id, label: a.chinese_name + ' ' + a.name };
          });
          return sum;
        }, {});
        const _banners = {}
        Object.keys(data.banners).forEach(item=>{
          if (item==='home'){
            const homeBanner = []
            let feed = socialFeed?.data?.data
            if (feed && feed.length > 0){
              feed = feed.filter(item=>item.type==='article')
              const max = feed.length > 5 ? 5 : feed.length
              const targetFeed = feed.slice(0, max).map(item=>{
                return{
                  id: item.id,
                  page: 'article',
                  list: null,
                  url: '#',
                  image: (item.images && item.images.length > 0) ? item.images[0]: null,
                  title: item.title,
                }
              })
              homeBanner.push(...targetFeed)
            }
            _banners['home'] = [...homeBanner, ...data.banners[item]]
          }else {
            _banners[item] = data.banners[item]
          }
        })
        self.currentActivity = data.currentActivity;
        self.banners = _banners;
        self.area = _area;
        self.city = _city;
        self.category = data.category;
      }
    });
    return { init };
  });

export const ConfigStoreInitialValues = {
  banners: {
    cheliang: [],
    home: [],
    ershou: [],
    fang: [],
    fuwu: [],
    gongzuo: [],
    huodong: [],
    lvyou: [],
    float: [],
    aixue: []
  }
};

export default ConfigStore;
