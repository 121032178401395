import ConfigStore, { ConfigStoreInitialValues } from './ConfigStore';
import UserStore, { initUserValue } from './UserStore';
import TopicStore from './TopicStore';
import TopicNewCarUIStore from './TopicNewCarUIStore';
import MerchantStore from './MerchantStore';
import FilterStore from './FilterStore';
import UIStore from './UIStore';
import RegisterUIStore from './RegisterUIStore';
import WatchListStore from './WatchListStore';
import GroupStore from './Group/GroupStore';
import SimilarListStore from './SimilarListStore';
import EventRegisterStore from './EventRegisterStore';
import { types, applySnapshot, unprotect } from 'mobx-state-tree';
import SearchListingStore from './SearchListingStore';
import ArticleStore from './ArticleStore';
import SimilarArticleStore from './SimilarArticleStore';
import HomePageFeedStore from './HomePageFeedStore';
import SocialFeedStore from './SocialFeedStore';

const RootStore = types
  .model({
    userStore: UserStore,
    uiStore: UIStore,
    filterStore: FilterStore,
    groupStore: GroupStore,
    searchListingStore: SearchListingStore,
    configStore: ConfigStore,
    topicStore: TopicStore,
    topicNewCarUIStore: TopicNewCarUIStore,
    eventRegisterStore: EventRegisterStore,
    watchListStore: WatchListStore,
    similarListStore: SimilarListStore,
    merchantStore: MerchantStore,
    registerUIStore: RegisterUIStore,
    articleStore: ArticleStore,
    similarArticleStore: SimilarArticleStore,
    homePageFeedStore: HomePageFeedStore,
    socialFeedStore: SocialFeedStore
  })
  .actions(self => ({
    // afterCreated() {
    //   console.log(6000);
    //   self.configStore.init().then();
    // }
  }));

// const isServer = typeof window === 'undefined'
// useStaticRendering(isServer)
const userStoreInitialValue = {
  jwt: '',
  user: initUserValue,
  unReadCommentsMsgCount: 0,
  unReadSystemMsgCount: 0,
  newTopic: {
    topic_id: 0,
    category_name: ''
  },
  myTopics: []
};

const uiStoreInitialValue = {
  AfterRegisterPath: '/index',
  merchantTagIndex: null
};

let store = null;

export function initializeStore(isServer, snapshot = null) {
  if (isServer || store === null) {
    store = RootStore.create({
      userStore: UserStore.create(userStoreInitialValue),
      uiStore: UIStore.create(uiStoreInitialValue),
      filterStore: FilterStore.create(),
      groupStore: GroupStore.create(),
      searchListingStore: SearchListingStore.create(),
      configStore: ConfigStoreInitialValues,
      topicStore: TopicStore.create(),
      topicNewCarUIStore: TopicNewCarUIStore.create(),
      eventRegisterStore: EventRegisterStore.create(),
      watchListStore: WatchListStore.create(),
      similarListStore: SimilarListStore.create(),
      merchantStore: MerchantStore.create(),
      registerUIStore: RegisterUIStore.create(),
      articleStore: ArticleStore.create(),
      similarArticleStore: SimilarArticleStore.create(),
      homePageFeedStore: HomePageFeedStore.create({ data: [], cursor: '' }),
      socialFeedStore: SocialFeedStore.create({ data: [], cursor: '' })
    });
  }
  if (snapshot) {
    applySnapshot(store, snapshot);
  }
  unprotect(store);
  return store;
}
