import { types, getParent, flow } from 'mobx-state-tree';
import { userModel } from './UserStore';
import api from '../api';

const ArticleModel = types.model('ArticleModel', {
  id: types.number,
  content: types.string,
  created_at: types.string,
  tags: types.maybeNull(types.array(types.string)),
  title: types.string,
  user: types.maybeNull(userModel),
  view_count: types.number,
  user_id: types.number,
  images: types.maybeNull(types.array(types.string))
});

const ArticleStore = types
  .model('ArticleStore', {
    article: types.maybeNull(ArticleModel),
    ad: types.frozen(),
    id: types.maybe(types.string),
    city: types.maybeNull(types.string)
  })
  .actions(self => ({
    setLocation(city){
      self.city = city
      if (city && self.ad) {
        const ads = self.ad.filter(item=>{
          return (!item.location || item.location.toLowerCase()=== city.toLowerCase())
        })
        if (ads && ads.length > 0){
          self.ad = ads
        }else {
        }
      }
    },
    setArticleData(data) {
      self.article = data.article;
      self.ad = data.ad;
      if (self.city){
        const ads = self.ad.filter(item=>{
          return (!item.location || item.location.toLowerCase()=== self.city.toLowerCase())
        })
        if (ads && ads.length > 0){
          self.ad = ads
        }
      }
    },
    fetchArticle: flow(function* fetchArticle(id) {
      getParent(self).uiStore.showLoading();
      const { data } = yield api.article.get(id);

      if (!data.code) {
        self.setArticleData(data);
      }
      getParent(self).uiStore.hideLoading();
    })
  }));
export default ArticleStore;
