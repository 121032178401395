import { observable, action, computed, runInAction, autorun } from 'mobx';
import { topic } from '../api';
import { TOPIC_CATEGORY, TopicModel } from '../mobx/TopicStore';
import _ from 'lodash';
import { flow, types, getParent } from 'mobx-state-tree';

const carFilterInitValue = {
  make: '',
  model: '',
  price_from: null,
  price_to: null,
  year_from: null,
  year_to: null,
  mileage_from: 0,
  mileage_to: 0,
  cc_from: 0,
  cc_to: 0,
  fuel_type: null,
  transmission: null,
  seller_type: ''
};

const CarFilterModel = types.model('CarFilterModel', {
  make: types.maybeNull(types.string),
  model: types.maybeNull(types.string),
  price_from: types.maybeNull(types.number),
  price_to: types.maybeNull(types.number),
  year_from: types.maybeNull(types.number),
  year_to: types.maybeNull(types.number),
  mileage_from: types.maybeNull(types.number),
  mileage_to: types.maybeNull(types.number),
  cc_from: types.maybeNull(types.number),
  cc_to: types.maybeNull(types.number),
  fuel_type: types.maybeNull(types.string),
  transmission: types.maybeNull(types.string),
  seller_type: types.maybeNull(types.string)
});

const fangFilterInitValue = {
  price: '',
  rental_range: [],
  huXing: ['', '', ''],
  zuShou: '',
  genderPreference: '',
  roomType: '',
  shangPuType: '',
  rentType: ''
};

const FangFilterModel = types.model('FangFilterModel', {
  price: types.string,
  rental_range: types.array(types.number),
  huXing: types.array(types.string),
  zuShou: types.string,
  genderPreference: types.string,
  roomType: types.string,
  shangPuType: types.string,
  rentType: types.string
});

const ErshouFilterModel = types.model('ErshouFilterModel', {
  condition: types.string,
  //  原 third_category_name， 现改为 itemCategory
  itemCategory: types.string
});

const ershouFilterInitValue = {
  condition: '',
  //  原 third_category_name， 现改为 itemCategory
  itemCategory: ''
};

const JobFilterModel = types.model('JobFilterModel', {
  salary: types.number,
  type: types.string,
  //  原 third_category_name， 现改为 itemCategory
  itemCategory: types.string
});

const jobFilterInitValue = {
  salary: 0,
  type: '',
  //  原 third_category_name， 现改为 itemCategory
  itemCategory: ''
};

const predefinedCarTags = new Set([
  'location',
  'sortBy',
  'make',
  'model',
  'yearRange',
  'sellerType',
  'priceRange',
  'mileageRange',
  'ccRange',
  'fuelType',
  'transmission'
]);
const predefinedFangTags = new Set([
  'location',
  'sortBy',
  'rentalRange',
  'shareRent',
  'huXing',
  'zuShou',
  'genderPreference',
  'roomType',
  'shangPuType',
  'rentType'
]);

const predefinedUsedTags = new Set([
  'location',
  'sortBy',
  'condition',
  'itemCategory'
]);

const predefinedJobTags = new Set([
  'location',
  'sortBy',
  'salary',
  'jobType',
  'itemCategory'
]);

const predefinedLocalServicesTags = new Set(['location', 'sortBy']);

const predefinedCommonTags = new Set(['location', 'sortBy']);

const TagsModel = types.model('TagsModel', {
  sellerType: types.string,
  location: types.string,
  sortBy: types.string,
  make: types.string,
  model: types.string,
  priceRange: types.string,
  rentalRange: types.string,
  yearRange: types.string,
  mileageRange: types.string,
  ccRange: types.string,
  fuelType: types.string,
  transmission: types.string,
  shareRent: types.string,
  huXing: types.string,
  condition: types.string,
  salary: types.string,
  jobType: types.string,
  itemCategory: types.string,
  zuShou: types.string,
  genderPreference: types.string,
  roomType: types.string,
  shangPuType: types.string,
  rentType: types.string
});

const tagsInitValue = {
  sellerType: '',
  location: '',
  sortBy: '',
  make: '',
  model: '',
  priceRange: '',
  rentalRange: '',
  yearRange: '',
  mileageRange: '',
  ccRange: '',
  fuelType: '',
  transmission: '',
  shareRent: '',
  huXing: '',
  condition: '',
  salary: '',
  jobType: '',
  itemCategory: '',
  zuShou: '',
  genderPreference: '',
  roomType: '',
  shangPuType: '',
  rentType: ''
};

const FilterStore = types
  .model('FilterModel', {
    isLastParams: true,
    nextPageNumber: 1,
    currentFilter: types.frozen(),
    city_id: types.maybeNull(types.number),
    area_id: types.maybeNull(types.number),
    sub_area_id: types.maybeNull(types.number),
    keyword: types.maybeNull(types.string),
    merchant_id: types.maybeNull(types.string),
    sort_by: types.maybe(types.string),
    topics: types.array(TopicModel),
    category_name: types.maybeNull(types.string),
    sub_category_name: types.maybeNull(types.string),
    itemCategory: types.maybe(types.string),
    tags: types.optional(TagsModel, tagsInitValue),
    fangFilter: types.optional(FangFilterModel, fangFilterInitValue),
    carFilter: types.optional(CarFilterModel, carFilterInitValue),
    ershouFilter: types.optional(ErshouFilterModel, ershouFilterInitValue),
    jobFilter: types.optional(JobFilterModel, jobFilterInitValue),
    loading: false,
    hasMore: true,
    showRecommendedList: false
  })
  .views(self => ({
    get getTags() {
      let values = [];
      switch (self.category_name) {
        case TOPIC_CATEGORY.CAR: {
          for (let key in self.tags) {
            if (predefinedCarTags.has(key)) {
              const value = self.tags[key];
              values.push(value);
            }
          }
          break;
        }
        case TOPIC_CATEGORY.FANG: {
          for (let key in self.tags) {
            if (predefinedFangTags.has(key)) {
              const value = self.tags[key];
              values.push(value);
            }
          }
          break;
        }
        case TOPIC_CATEGORY.USED: {
          for (let key in self.tags) {
            if (predefinedUsedTags.has(key)) {
              const value = self.tags[key];
              values.push(value);
            }
          }
          break;
        }
        case TOPIC_CATEGORY.JOB: {
          for (let key in self.tags) {
            if (predefinedJobTags.has(key)) {
              const value = self.tags[key];
              values.push(value);
            }
          }
          break;
        }
        case TOPIC_CATEGORY.SERVICE: {
          for (let key in self.tags) {
            if (predefinedLocalServicesTags.has(key)) {
              const value = self.tags[key];
              values.push(value);
            }
          }
          break;
        }
        default: {
          for (let key in self.tags) {
            if (predefinedCommonTags.has(key)) {
              const value = self.tags[key];
              values.push(value);
            }
          }
        }
      }

      return values.filter(item => {
        return item && item !== '';
      });
    }
  }))
  .actions(self => ({
    initParams() {
      self.nextPageNumber = 1;
      self.hasMore = true;
      self.topics = [];
    },
    afterCreate() {
      // 二手车
      autorun(async () => {
        if (self.category_name !== TOPIC_CATEGORY.CAR) {
          return;
        }
        let params = {};
        self.initParams();
        self.keyword && (params.keyword = self.keyword);
        self.category_name && (params.category_name = self.category_name);
        self.carFilter.make && (params.make = self.carFilter.make);
        self.carFilter.model && (params.model = self.carFilter.model);
        self.carFilter.price_from &&
        (params.price_from = self.carFilter.price_from);
        self.carFilter.price_to && (params.price_to = self.carFilter.price_to);
        self.carFilter.year_from &&
        (params.year_from = self.carFilter.year_from);
        self.carFilter.year_to && (params.year_to = self.carFilter.year_to);
        self.carFilter.mileage_from &&
        (params.kilometre_from = self.carFilter.mileage_from);
        self.carFilter.mileage_to &&
        (params.kilometre_to = self.carFilter.mileage_to);
        self.carFilter.cc_from && (params.cc_from = self.carFilter.cc_from);
        self.carFilter.cc_to && (params.cc_to = self.carFilter.cc_to);
        self.carFilter.fuel_type &&
        (params.fuel_type = self.carFilter.fuel_type);
        self.carFilter.transmission &&
        (params.transmission = self.carFilter.transmission);
        self.carFilter.seller_type !== '' &&
        (params.list_type = self.carFilter.seller_type);
        self.sort_by !== '' && (params.sort_by = self.sort_by);
        self.city_id && (params.city_id = self.city_id);
        self.area_id && (params.area_id = self.area_id);
        self.sub_area_id && (params.sub_area_id = self.sub_area_id);
        self.currentFilter = params;
        await self.fetchResultFromServer(params);
      });
      // 租房板块
      autorun(async () => {
        if (self.category_name !== TOPIC_CATEGORY.FANG) {
          return;
        }
        let params = {};
        self.initParams();
        self.keyword && (params.keyword = self.keyword);
        self.sort_by !== '' && (params.sort_by = self.sort_by);
        self.category_name && (params.category_name = self.category_name);
        self.sub_category_name &&
        (params.sub_category_name = self.sub_category_name);
        if (self.fangFilter.rental_range) {
          const range = self.fangFilter.rental_range.toJS();
          range[0] && (params.rent_per_week_from = range[0]);
          range[1] && (params.rent_per_week_to = range[1]);
        }
        self.fangFilter.huXing[0] &&
        (params.bedroom = self.fangFilter.huXing[0]);
        self.fangFilter.huXing[1] &&
        (params.bathroom = self.fangFilter.huXing[1]);
        self.fangFilter.huXing[2] &&
        (params.car_park = self.fangFilter.huXing[2]);
        self.fangFilter.zuShou && (params.zuShou = self.fangFilter.zuShou);
        self.fangFilter.genderPreference &&
        (params.genderPreference = self.fangFilter.genderPreference);
        self.fangFilter.roomType &&
        (params.roomType = self.fangFilter.roomType);
        self.fangFilter.shangPuType &&
        (params.shangPuType = self.fangFilter.shangPuType);
        self.fangFilter.rentType &&
        (params.rentType = self.fangFilter.rentType);
        self.city_id && (params.city_id = self.city_id);
        self.area_id && (params.area_id = self.area_id);
        self.sub_area_id && (params.sub_area_id = self.sub_area_id);
        self.currentFilter = params;
        await self.fetchResultFromServer(params);
      });

      //二手闲置物品
      autorun(async () => {
        if (self.category_name !== TOPIC_CATEGORY.USED) {
          return;
        }
        let params = {};
        self.initParams();
        self.keyword && (params.keyword = self.keyword);
        self.sort_by !== '' && (params.sort_by = self.sort_by);
        self.category_name && (params.category_name = self.category_name);
        self.sub_category_name &&
        (params.sub_category_name = self.sub_category_name);
        self.itemCategory !== '' && (params.itemCategory = self.itemCategory);
        self.city_id && (params.city_id = self.city_id);
        self.area_id && (params.area_id = self.area_id);
        self.sub_area_id && (params.sub_area_id = self.sub_area_id);
        self.currentFilter = params;
        await self.fetchResultFromServer(params);
      });

      //求职招聘
      autorun(async () => {
        if (self.category_name !== TOPIC_CATEGORY.JOB) {
          return;
        }
        let params = {};
        self.initParams();
        self.keyword && (params.keyword = self.keyword);
        self.sort_by !== '' && (params.sort_by = self.sort_by);
        self.category_name && (params.category_name = self.category_name);
        self.sub_category_name &&
        (params.sub_category_name = self.sub_category_name);
        self.itemCategory !== '' && (params.role = self.itemCategory);
        self.city_id && (params.city_id = self.city_id);
        self.area_id && (params.area_id = self.area_id);
        self.sub_area_id && (params.sub_area_id = self.sub_area_id);
        self.jobFilter.salary !== 0 && (params.salary = self.jobFilter.salary);
        self.jobFilter.type && (params.jobType = self.jobFilter.type);
        self.currentFilter = params;
        await self.fetchResultFromServer(params);
      });

      //本地服务
      autorun(async () => {
        if (self.category_name !== TOPIC_CATEGORY.SERVICE) return;
        let params = {};
        self.initParams();
        self.keyword && (params.keyword = self.keyword);
        self.sort_by !== '' && (params.sort_by = self.sort_by);
        self.category_name && (params.category_name = self.category_name);
        self.sub_category_name &&
        (params.sub_category_name = self.sub_category_name);
        self.itemCategory !== '' && (params.itemCategory = self.itemCategory);
        self.city_id && (params.city_id = self.city_id);
        self.area_id && (params.area_id = self.area_id);
        self.sub_area_id && (params.sub_area_id = self.sub_area_id);
        self.currentFilter = params;
        await self.fetchResultFromServer(params);
      });

      //其他分类
      autorun(async () => {
        if (
          self.category_name === TOPIC_CATEGORY.CAR ||
          self.category_name === TOPIC_CATEGORY.FANG ||
          self.category_name === TOPIC_CATEGORY.USED ||
          self.category_name === TOPIC_CATEGORY.JOB ||
          self.category_name === TOPIC_CATEGORY.SERVICE
        ) {
          return;
        }
        //重新进入首页，无需加载topic list
        if (!self.category_name && !self.keyword) {
          return;
        }
        let params = {};
        self.initParams();
        self.keyword && (params.keyword = self.keyword);
        self.sort_by !== '' && (params.sort_by = self.sort_by);
        self.category_name && (params.category_name = self.category_name);
        self.city_id && (params.city_id = self.city_id);
        self.area_id && (params.area_id = self.area_id);
        self.sub_area_id && (params.sub_area_id = self.sub_area_id);
        self.currentFilter = params;
        await self.fetchResultFromServer(params);
      });

      // 无分类
      autorun(async () => {
        if (!_.isEmpty(self.category_name)) {
          return;
        }
        if (!self.showRecommendedList) {
          return;
        }
        let params = {};
        self.initParams();
        self.keyword && (params.keyword = self.keyword);
        self.sort_by !== '' && (params.sort_by = self.sort_by);
        self.city_id && (params.city_id = self.city_id);
        self.area_id && (params.area_id = self.area_id);
        self.sub_area_id && (params.sub_area_id = self.sub_area_id);
        self.currentFilter = params;
        await self.fetchResultFromServer(params);
      });
    },
    setRecommendation(value) {
      self.showRecommendedList = value;
    },
    waitLastParams(value) {
      self.isLastParams = value;
    },
    setItemCategory(value) {
      self.itemCategory = value;
    },
    setCity(id) {
      self.city_id = id;
      self.area_id = null;
      self.sub_area_id = null;
    },
    setArea(areaId) {
      self.area_id = areaId;
      self.sub_area_id = null;
    },
    setSubArea(id) {
      self.sub_area_id = id;
    },
    setKeyword(keyword) {
      self.initParams();
      self.keyword = keyword;
    },
    setGroupId(groupId) {
      self.group_id = groupId;
    },
    setTags(tag) {
      Object.assign(self.tags, tag);
    },
    setSortBy(type) {
      self.sort_by = type;
    },

    clearSortBy() {
      self.sort_by = '';
    },

    setAreaId(area_id) {
      self.area_id = area_id;
    },

    resetLocation() {
      self.city_id = null;
      self.area_id = null;
      self.sub_area_id = null;
    },
    resetCarPrice() {
      self.setCarFilter({ price_from: null });
      self.setCarFilter({ price_to: null });
    },

    resetCarYear() {
      self.setCarFilter({ year_from: null });
      self.setCarFilter({ year_to: null });
    },

    resetCarMileage() {
      self.setCarFilter({ mileage_from: 0 });
      self.setCarFilter({ mileage_to: 0 });
    },

    resetCarCC() {
      self.setCarFilter({ cc_from: 0 });
      self.setCarFilter({ cc_to: 0 });
    },
    setCategoryName(category, clearFilterStore) {
      let name = '';
      switch (category) {
        case TOPIC_CATEGORY.CAR:
          name = 'cheliang';
          break;
        case TOPIC_CATEGORY.FANG:
          name = 'fang';
          break;
        case TOPIC_CATEGORY.JOB:
          name = 'gongzuo';
          break;
        case TOPIC_CATEGORY.USED:
          name = 'ershou';
          break;
        case TOPIC_CATEGORY.ACTIVITY:
          name = 'huodong';
          break;
        case TOPIC_CATEGORY.TRIP:
          name = 'lvyou';
          break;
        case TOPIC_CATEGORY.SERVICE:
          name = 'fuwu';
          break;
        // patch compatible with previous category
        case 'zhuangxiu':
          name = 'fuwu';
          break;
        // patch end
        case TOPIC_CATEGORY.BUSINESS:
          name = 'shengyi';
          break;
        case TOPIC_CATEGORY.TUANTUAN:
          name = 'tuantuan';
          break;
        case TOPIC_CATEGORY.SOCIAL:
          name = 'shequ';
          break;
        default:
          name = null;
          break;
      }
      if (
        clearFilterStore === true ||
        (category !== self.category_name && clearFilterStore === undefined)
      ) {
        self.clearFilterStore();
      }
      self.category_name = name;
    },
    setSubCategoryName(value) {
      self.sub_category_name = value;
    },

    setFangFilter(filter) {
      Object.assign(self.fangFilter, filter);
    },

    setCarFilter(filter) {
      Object.assign(self.carFilter, filter);
    },

    setErshouFilter(filter) {
      Object.assign(self.ershouFilter, filter);
    },

    setJobFilter(filter) {
      Object.assign(self.jobFilter, filter);
    },
    clearAreaName() {
      self.tags.areaName = '';
    },

    clearTagsAndFilterStore() {
      self.tags = tagsInitValue;
      self.fangFilter = fangFilterInitValue;
      self.carFilter = carFilterInitValue;
      self.ershouFilter = ershouFilterInitValue;
      self.jobFilter = jobFilterInitValue;
      self.sort_by = '';
      self.itemCategory = '';
      self.setRecommendation(false);
      self.resetLocation();
    },
    clearFilterStore() {
      self.city_id = 0;
      self.area_id = null;
      self.sub_area_id = null;
      self.nextPageNumber = 1;
      self.currentFilter = {};
      self.keyword = null;
      self.merchant_id = null;
      self.sort_by = '';
      self.topics = [];
      self.category_name = null;
      self.sub_category_name = null;
      self.itemCategory = '';
      self.tags = tagsInitValue;
      self.fangFilter = fangFilterInitValue;
      self.carFilter = carFilterInitValue;
      self.ershouFilter = ershouFilterInitValue;
      self.jobFilter = jobFilterInitValue;
      self.loading = false;
      self.hasMore = true;
    },
    fetchResultFromServer: flow(function* fetchResultFromServer(
      param = self.currentFilter,
      recommond = false
    ) {
      if (self.hasMore && !self.loading && self.isLastParams) {
        self.loading = true;
        let params = Object.assign({}, param);
        params.page = self.nextPageNumber++;
        // self.keyword && (params.keyword = self.keyword);
        self.setRecommendation(recommond);
        const { data } = yield topic.search(params);
        if (data) {
          // 如果 topics ===0 && data === 0 才能确认没有数据
          console.log(self.topics.length)
          if (!_.isEmpty(data.data)) {
            self.topics = [...self.topics, ...data.data];
            self.hasMore = self.nextPageNumber <= data.meta.last_page;
          } else if (_.isEmpty(self.topics)) {
            self.loadRecommond();
          } else {
            self.hasMore = false;
          }
          self.loading = false;
        }
      }
    }),
    loadNextPage: flow(function* () {
      yield self.fetchResultFromServer(
        self.showRecommendedList
          ? { category_name: self.currentFilter.category_name }
          : self.currentFilter,
        self.showRecommendedList
      );
    }),
    loadRecommond() {
      self.nextPageNumber = 1;
      self.fetchResultFromServer(
        { category_name: self.currentFilter.category_name, page: 0 },
        true
      );
    }
  }));

export default FilterStore;
