import api from '../api';
import { types, flow, getParent } from 'mobx-state-tree';
import { userModel } from './UserStore';
import { formatMobile } from '../util';

const EventRegisterStore = types
  .model('EventRegisterModel', {
    name: types.maybeNull(types.string),
    mobile: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    numberOfPeople: types.maybeNull(types.string),
    eventId: types.maybe(types.number),
    eventName: types.maybe(types.string),
    ownerId: types.maybeNull(types.number),
    meta: types.frozen(),
    signUpUsers: types.maybeNull(types.array(userModel)),
    groupMsg: types.optional(types.string, '')
  })
  .actions(self => ({
    getParams() {
      let params = {
        topicId: self.eventId,
        name: self.name,
        number: self.numberOfPeople,
        meta: self.meta
      };

      if (self.mobile !== '') {
        params = { ...params, mobile: self.mobile };
      }

      if (self.email !== '') {
        params = { ...params, email: self.email };
      }

      return params;
    },
    setUserInfo(user) {
      self.email = user.email;
      if (user.mobile && user.mobile.indexOf('64') === 0) {
        self.mobile = formatMobile(user.mobile);
      }
    },
    setEvent(topic) {
      self.eventId = topic.id;
      self.eventName = topic.title;
      self.ownerId = topic.user ? topic.user.id : null;
    },
    setGroupMsg(groupMsg) {
      self.groupMsg = groupMsg;
    },
    setName(name) {
      self.name = name;
    },
    setMobile(mobile) {
      self.mobile = mobile;
    },
    setEmail(email) {
      self.email = email;
    },
    setNumberOfPeople(number) {
      self.numberOfPeople = number;
    },
    setUsers(data) {
      self.signUpUsers = data;
    },
    setMeta(data){
      self.meta = {
        ...self.meta,
        ...data
      }
    },
    clearStore() {
      self.name = '';
      self.mobile = '';
      self.email = '';
      self.numberOfPeople = '';
      self.signUpUsers = [];
      self.meta = {}
    },
    createNewAttendee: flow(function*() {
      const params = self.getParams();
      const { data } = yield api.activity.create(params);
      if (self.ownerId && self.ownerId !== getParent(self).userStore.user.id) {
        self.contentSeller();
      }
      return !!(data && !data.code);
    }),
    createNewAttendee2: flow(function*() {
      const params = self.getParams();
      const { data } = yield api.activity.create2(params);
      if (self.ownerId && self.ownerId !== getParent(self).userStore.user.id) {
        self.contentSeller();
      }
      return !!(data && !data.code);
    }),
    contentSeller: flow(function*() {
      let content = '我已报名参加你组织的"' +
      self.eventName +
      '"，我的报名信息如下：\n姓名：' +
      self.name +
      '\n邮箱：' +
      self.email +
      '\n 人数：' +
      self.numberOfPeople
      if (self.meta){
        content = '我已报名参加你组织的"' +
          self.eventName +
          '"，我的报名信息如下：\n' +
          JSON.stringify(self.meta)
      }
      api.my.contactSeller({
        topic_id: self.eventId,
        content: content
      });
    }),
    getSignUpUsers: flow(function*() {
      const { data } = yield api.activity.getSignUpUsers(self.eventId);
      if (data && !data.code) {
        self.setUsers(data);
      }
    }),
    sendGroupMsg: async () => {
      getParent(self).uiStore.showLoading();
      const { data } = await api.activity.sendMsg({
        topic_id: self.eventId,
        content: self.groupMsg
      });
      if (data && !data.code) {
        self.setGroupMsg('');
      }
      getParent(self).uiStore.hideLoading();
    }
  }));

export default EventRegisterStore;
